<template>
  <v-card class="border-solid" elevation="0">
    <v-card-title>Realizar una donación</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-stepper
        v-model="e1"
        class="elevation-0"
        style="box-shadow: none !important"
      >
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="e1 > 1" step="1" color="secondary">
            Datos de donación
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2" color="secondary">
            Método de Pago
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" color="secondary">
            Confirmación
          </v-stepper-step>
        </v-stepper-header>

        <v-form>
          <v-stepper-items style="box-shadow: none">
            <!-- begin:: Paso 1 -->
            <v-stepper-content step="1">
              <v-card elevation="0">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        label="Programa a Donar:"
                        outlined
                        hint="Seleccione un programa al cual desea donar"
                        persistent-hint
                        required
                        v-model="donation.id_donation_type"
                        :items="typeDonations"
                        item-text="donation_type"
                        item-value="id_donation_type"
                        @change="slcDonationType"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Cantidad a donar"
                        outlined
                        required
                        v-model="donation.amount"
                        prepend-inner-icon="mdi-currency-usd"
                        hint="La cantidad minima a donar son $ 5.00"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        label="Año lectivo al que desea donar"
                        required
                        outlined
                        hint="Puede elegir a que año lectivo realizar su donación"
                        persistent-hint
                        v-model="donation.year"
                        :items="years"
                        item-text="year"
                        item-value="year"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-btn color="primary" block @click="step1"> Siguiente </v-btn>
            </v-stepper-content>
            <!-- end:: Paso 1 -->
            <!-- begin:: Paso 2 -->
            <v-stepper-content step="2">
              <v-card class="mb-12 elevation-0">
                <v-row>
                  <v-col>
                    <div class="d-flex justify-center align-center mb-5">
                      <v-img
                        lazy-src="@/assets/visa-master.png"
                        max-width="200"
                        src="@/assets/visa-master.png"
                        class="mx-auto"
                      ></v-img>
                      <v-img
                        lazy-src="@/assets/logo-union-pay.png"
                        max-width="120"
                        src="@/assets/logo-union-pay.png"
                        class="mx-auto"
                      ></v-img>
                      <v-img
                        lazy-src="@/assets/logo-serfinsa.png"
                        max-width="200"
                        src="@/assets/logo-serfinsa.png"
                        class="mx-auto"
                      ></v-img>
                    </div>
                    <p class="mb-0">
                      <b>Nota: </b>La información de su tarjeta de crédito o
                      débito no se registran, ya que la transacción se realiza
                      con
                      <i
                        ><a href="https://www.redserfinsa.com/" target="_blank"
                          >Serfinsa</a
                        ></i
                      >
                      y además de aceptar cualquier tipo de tarjeta ya sean<i
                        >visa, mastercard o union pay</i
                      >
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Seleccione su país de residencia"
                      outlined
                      hide-details
                      v-model="donation.id_country"
                      :items="countries"
                      item-text="country"
                      item-value="id_country"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div
                      class="d-flex align-center justify-center mx-auto"
                      v-if="this.donation.id_country == 68"
                    >
                      <v-checkbox
                        label="¿Desea comprobante?"
                        hide-details
                        class="d-inline-block mt-0 mr-3"
                        v-model="donation.voucher"
                        @change="check"
                      ></v-checkbox>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span
                          >El comprobante es solo para motivos de declaración de
                          renta y solo es para residentes de El Salvador</span
                        >
                      </v-tooltip>
                    </div>
                    <div class="d-md-none d-lg-none">
                      <p class="text-caption text-center">
                        El comprobante es solo para motivos de declaración de
                        renta y solo es para residentes de El Salvador
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="this.nitNull != false">
                  <v-col cols="12">
                    <v-text-field
                      label="NIT"
                      type="text"
                      outlined
                      hide-details
                      v-model="donation.nit"
                      v-mask="'####-######-###-#'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      type="text"
                      label="Nombre de Tarjeta"
                      outlined
                      required
                      hide-details
                      v-model="donation.card_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Número de la Tarjeta"
                      outlined
                      required
                      hide-details
                      prepend-icon=""
                      prepend-inner-icon="mdi-credit-card-outline"
                      v-mask="'#### #### #### ####'"
                      v-model="donation.card_number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Fecha de expiración (mm/yy)"
                      outlined
                      required
                      hide-details
                      prepend-icon=""
                      prepend-inner-icon="mdi-credit-card"
                      v-mask="'##/##'"
                      v-model="donation.expiration_date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Código de seguridad CVV"
                      outlined
                      required
                      hide-details
                      prepend-icon=""
                      prepend-inner-icon="mdi-card-search-outline"
                      v-mask="'###'"
                      v-model="donation.cvv"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col>
                  <v-btn color="secondary" block text @click="e1 = 1">
                    Regresar
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn color="primary" block @click="step2">
                    Siguiente
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!-- end:: Paso 2 -->

            <!-- begin:: Paso 3 -->
            <v-stepper-content step="3">
              <v-card class="mb-12 elevation-0">
                <p class="body-1">
                  <span class="font-weight-medium">Nombre completo:</span>
                  {{ user.name }}
                </p>
                <p class="body-1">
                  <span class="font-weight-medium">Número de Tarjeta:</span>
                  {{ donation.card_number }}
                </p>
                <p class="body-1">
                  <span class="font-weight-medium">Cantidad a donar: </span>
                  ${{ donation.amount }}
                </p>
                <p class="body-1">
                  <span class="font-weight-medium">Donación para:</span>
                  {{ selectedDonationType.donation_type }}
                </p>
                <v-row>
                  <v-col class="d-flex flex-column justify-center align-center">
                    <p class="text-body-1">
                      ¡Queremos comprobar que eres un humano! Completa el
                      captcha
                    </p>
                    <!-- Turnstile -->
                    <turnstile ref="turnstile" @verified="handleVerification" />
                  </v-col>
                </v-row>

                <!-- begin:: Código de verificación -->
                <v-row>
                  <v-col class="d-flex flex-column justify-center align-center">
                    <h6 class="text-h6">Comprobación de identidad</h6>
                    <p class="text-body-1 mx-3">
                      Hemos envíado un código de 6 digitos a tu correo
                      electrónico, verifica tu bandeja de entrada o bandeja de
                      Spam e ingresa el correo que te hemos enviado.
                    </p>
                    <v-text-field
                      label="Código de verificación"
                      required
                      outlined
                      hide-details
                      v-mask="'######'"
                      v-model="donation.codigo_verificacion"
                    ></v-text-field>
                    <p
                      v-if="verificationCodeTimerLabel > 0"
                      class="text-body-2 my-1"
                    >
                      Reenviar en {{ verificationCodeTimerLabel }} segundos
                    </p>
                    <v-btn
                      class="mt-3"
                      color="secondary"
                      :disabled="verificationCodeTimerLabel > 0"
                      @click="reenviarCodigo"
                    >
                      Solicitar código de verificación
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- end:: Código de verificación -->
              </v-card>

              <v-btn text @click="e1 = 2"> Regresar </v-btn>
              <v-btn color="primary" @click="formDonation" :loading="loading">
                Finalizar
              </v-btn>
            </v-stepper-content>
            <!-- end:: Paso 3 -->
          </v-stepper-items>
        </v-form>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Turnstile from "@/components/Turnstile.vue";
import { isAxiosError } from "axios";
export default {
  name: "Donation",
  components: {
    Turnstile,
  },
  data: () => ({
    e1: 1,
    nitNull: false,
    donation: {
      amount_serfinsa: 0,
      codigo_verificacion: "",
    },
    typeDonations: [],
    countries: [],
    selectedDonationType: {},
    years: [],
    loading: false,
    turnstileToken: null,
    verificationCodeTimerLabel: 0,
    verificationCodeCountdownInterval: null,
  }),
  mounted() {
    this.getTypeDonations();
    this.getCountries();
    this.getYears();
  },
  methods: {
    ...mapActions({
      getDonor: "Donor/getDonor",
      openSnackbar: "Snackbar/openSnackbar",
    }),

    getTypeDonations: async function () {
      await this.$http
        .get(this.api + "donation_type")
        .then(({ data }) => {
          this.typeDonations = data;
        })
        .catch((err) => {
          console.error("Error al obtener Tipos de Donaciones: " + err);
        });
    },

    getCountries: async function () {
      await this.$http
        .get(this.api + "country")
        .then(({ data }) => {
          this.countries = data;
        })
        .catch((err) => {
          console.error("Error al obtener Paises: " + err);
        });
    },

    getYears: function () {
      let year = new Date().getFullYear();
      this.years.push(year);
      year++;
      this.years.push(year);
    },

    check: function () {
      if (this.donation.voucher) {
        if (this.user.nit != null) {
          this.nitNull = false;
        } else if (this.user.nit == null) {
          this.nitNull = true;
        }
      } else {
        this.nitNull = false;
      }
    },

    step1: function () {
      let validate =
        this.donation.id_donation_type &&
        this.donation.amount &&
        this.donation.year;

      if (validate) {
        if (!isNaN(this.donation.amount) && this.donation.amount >= 5) {
          this.donation.amount = parseFloat(this.donation.amount).toFixed(2);
          this.donation.amount_serfinsa = this.donation.amount;
          this.e1++;
        } else {
          this.openSnackbar({
            type: "warning",
            message: "Por favor ingrese una cantidad correcta y no menos de $5",
          });
        }
      } else {
        this.openSnackbar({
          type: "warning",
          message: "Por favor, no deje campos vácios",
        });
      }
    },

    step2: function () {
      let validate =
        this.donation.id_country &&
        this.donation.card_name &&
        this.donation.card_number &&
        this.donation.expiration_date &&
        this.donation.cvv &&
        this.donation.amount &&
        this.donation.id_donation_type;

      if (validate) {
        if (this.donation.voucher) {
          this.donation.voucher = 1;
        } else {
          this.donation.voucher = 0;
        }
        this.e1++;
      } else {
        this.openSnackbar({
          type: "warning",
          message: "Por favor, no deje campos vácios",
        });
      }
    },

    formDonation: function () {
      this.loading = true;
      //format monto
      let monto = "000000000000";
      let str_monto = this.donation.amount.replace(".", "");
      this.donation.amount_serfinsa =
        monto.substr(0, monto.length - str_monto.length) + str_monto;
      let str_tarjeta = this.donation.card_number;
      this.donation.card_number_serfinsa = str_tarjeta.replace(/\s/g, "");
      let str_exp = this.donation.expiration_date;
      var exp = str_exp.split("/");
      this.donation.expiration_date_serfinsa = exp[1] + exp[0];
      this.donation.cvv_serfinsa = "1611 " + this.donation.cvv;
      this.donation.voucher = this.donation.voucher ? 1 : 0;
      this.donation.cf_token = this.turnstileToken;
      this.donation.email = this.user.email;

      this.$http
        .post(this.api + "tx/donation_frequent", this.donation)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.e1 = 1;
            this.donation = {};
            this.nitNull = false;
            this.getDonor(this);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error_message) {
            this.openSnackbar({
              type: "danger",
              message: err?.response?.data?.error_message,
            });
            return;
          }
          
          if (isAxiosError(err)) {
            this.openSnackbar({
              type: "danger",
              message: err.response.data.message,
            });
            return;
          }
          
          this.openSnackbar({
            type: "danger",
            message:
            "Ha ocurrido un error desconocido al intentar procesar la donación",
          });
        }).finally(() => {
          this.loading = false;
          this.$refs.turnstile.reload();
        });
    },

    slcDonationType: function () {
      //filtra todos los que sean diferentes a la id seleccionada (filtrar = convervar)
      let find = this.typeDonations.filter(
        (type) => type.id_donation_type == this.donation.id_donation_type
      );
      //hay algo? entonces la posicion 0 sera el seleccionado
      this.selectedDonationType = find.length > 0 ? find[0] : null;
    },

    handleVerification(turnstileToken) {
      this.turnstileToken = turnstileToken;
    },

    startTimer() {
      this.verificationCodeTimerLabel = 60;
      this.verificationCodeCountdownInterval = setInterval(() => {
        if (this.verificationCodeTimerLabel > 0) {
          this.verificationCodeTimerLabel--;
        } else {
          clearInterval(this.verificationCodeCountdownInterval);
        }
      }, 1000);
    },
    reenviarCodigo() {
      if (
        this.user.email === undefined ||
        this.user.email === null ||
        this.user.email == ""
      ) {
        this.openSnackbar({
          type: "danger",
          message:
            "Debes especificar un correo electrónico para recibir un código de verificación",
        });
        return;
      }
      this.startTimer();
      this.$http
        .post(this.api + "tx/donation/verification-code", {
          email: this.user.email,
        })
        .then(({ data }) => {
          this.openSnackbar({
            type: "success",
            message: data.message,
          });
        })
        .catch((err) => {
          if (isAxiosError(err)) {
            this.openSnackbar({
              type: "danger",
              message: err.response.data.message,
            });
            return;
          }

          this.openSnackbar({
            type: "danger",
            message:
              "Ha ocurrido un error desconocido al intentar solicitar el código de verificación",
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      token: "Donor/getToken",
      user: "Donor/getDonor",
    }),
  },
};
</script>

<style></style>
